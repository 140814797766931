import { StaticImage } from "gatsby-plugin-image"
import { DecorativeImageGridProps } from "../../components"

export const GalleryItems = [
  {
    Image: <StaticImage src="../../images/gallery_1.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_2.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_3.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_4.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_5.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_6.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_7.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_8.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_9.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_10.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_11.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_12.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_13.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_13.jpg" alt="" />,
  },
  {
    Image: <StaticImage src="../../images/gallery_13.jpg" alt="" />,
  },
]

export const DecoratorImages = [
  <StaticImage src="../../images/decorator_1.jpg" alt="" />,
  <StaticImage src="../../images/decorator_2.jpg" alt="" />,
  <StaticImage src="../../images/decorator_3.jpg" alt="" />,
  <StaticImage src="../../images/decorator_4.jpg" alt="" />,
] as DecorativeImageGridProps["Images"]
