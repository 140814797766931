// @ts-ignore
import React from "react"
import "twin.macro"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  Layout,
  SEO,
  Stats,
  StatsProps,
  StatsStory,
  Contact,
  ContactStory,
  Teaser,
  TeaserStory,
  Highlights,
  HighlightsStory,
  Location,
  LocationStory,
} from "../components"
import { Gallery } from "../gatsby-components"

const IndexPage = () => (
  <Layout>
    <SEO title="Startseite" />
    <Stats {...(StatsStory.args as StatsProps)} />
    <Teaser {...TeaserStory.args} />
    <StaticImage
      src="../images/seperator.jpg"
      alt="Neubauprojekt No.142 mit 20 Eigentumswohnungen"
      tw="my-16 md:my-24"
    />
    <Highlights
      tw="mb-16 lg:mb-28 overflow-hidden lg:(shadow-xl rounded-xl)"
      {...HighlightsStory.args}
    />
    <Location
      tw="bg-gray-50 py-16 lg:py-24"
      {...LocationStory.args}
      Image={<StaticImage src="../images/location.png" alt="Die Lage" />}
    />
    <Gallery tw="mt-16 mb-20 lg:mt-24" />
    <Contact {...ContactStory.args} PrivacyLabel={<PrviacyLabel />} />
  </Layout>
)

const PrviacyLabel = () => (
  <div>
    Ich habe die{" "}
    <Link
      tw="text-primary-darker hover:text-primary transition-colors inline"
      to="/datenschutz"
    >
      Datenschutzerklärung
    </Link>{" "}
    gelesen und akzeptiert.
  </div>
)

export default IndexPage
